import { setCSRFHeaders } from '~/javascript/utilities/ajax_helpers';

export const render = () => {
    const reloadUrl = $j("#get_clinical_case_completions_path").val();
    $j.get(reloadUrl).then(response => {
      const jsonResponse = JSON.parse(response);
      $j("#clinical_completion_status_container").html(jsonResponse.status_html);

      $j("#mark_clinically_complete_button_container").html(jsonResponse.mark_complete_button_html);
    });
};

export default render
